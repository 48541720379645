import httpInstance from "./httpClient"


const underlyingsRequest = (setUnderlyings) => {
    httpInstance.get('/getUnderlyingsList')
    .then(function (response) {
        setUnderlyings(response.data)
    })
    .catch(function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                console.log('User Logged Out')
            }
        }
        console.log(error);
    });
}

export default underlyingsRequest
