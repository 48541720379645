import { useState } from "react"
import { Card, Row, Form, Button, Table, Dropdown, Spinner } from 'react-bootstrap'

import useIV from "../hooks/useIV"
import useRiskFreeRate from "../hooks/useRiskFreeRate"

import genericRequestWithSpinner from "../utilities/genericRequestWithSpinner"
import dateListRequest from "../utilities/dateListRequest"


const BuyOptionsComboFinder = ( {selectedUnderlying, selectedExpiry, iv, setIV, selectedAtDate, setSelectedAtDate, setTrades} ) => {
    const [nextDaysRequested, setNextDaysRequested] = useState(false)
    const [volatility, setVolatility] = useState(0)
    const [changeType, setChangeType] = useState(0)
    const [changePerc, setChangePerc] = useState(0)
    const [atDateList, setAtDateList] = useState([])
    const [riskFreeRate, setRiskFreeRate] = useState({})
    const [minWinProb, setMinWinProb] = useState(0)
    
    const [risk, setRisk] = useState(0)
    const [spinnerFindTrade, setspinnerFindTrade] = useState(false)

    useIV(selectedUnderlying, selectedExpiry, setIV)
    useRiskFreeRate(setRiskFreeRate)

    if (!nextDaysRequested) {
        dateListRequest(selectedExpiry, setAtDateList)
        setNextDaysRequested(true)
    }

    const handleSelectPriceChange = (id) => {
        console.log('handleSelectPriceChange')
        setChangeType(Number(id));
    }

    const handleSelectAtDate = (id) => {
        console.log('handleSelectAtDate ' + id)
        setSelectedAtDate(atDateList[id]);
    }

    const onFindBuyTrade = async () => {
        console.log('onFindBuyTrade')
        setspinnerFindTrade(true)

        const requestData = {
            'symbol': selectedUnderlying.symbol,
            'secType': selectedUnderlying.secType,
            'currency': selectedUnderlying.currency,
            'expiry': selectedExpiry.name,
            'change_type': changeType,
            'perc_change': changePerc,
            'atDate': selectedAtDate.name,
            'volatility': volatility,
            'risk': risk,
            'minWinProb': minWinProb
        }
        genericRequestWithSpinner('FindOptionsBuyingTrade', requestData, setTrades, setspinnerFindTrade)
    }

    return (
        <Card className="h-100">
            <Card.Header>Combo Finder : Buying Options</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Row className="combo-finder-selling-options-menu">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th>Current IV (%)</th>
                                    <th>Risk Free Rate (%)</th>
                                    <th>Price</th>
                                    <th>By (%)</th>
                                    <th>By (date YYYYMMDD)</th>
                                    <th>Volatility (%)</th>
                                    <th>Risk ($)</th>
                                    <th>Min Win Prob (%)</th>
                                    <th style={{width: "10%"}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key="0">
                                    <td>
                                        <Form.Control size="sm" placeholder={iv.value} disabled/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" placeholder={riskFreeRate.value} disabled/>
                                    </td>
                                    <td>
                                        <Dropdown onSelect={handleSelectPriceChange}>
                                            <Dropdown.Toggle variant="secondary" id="changeType-selection" size="sm">
                                                {changeType === 0 ? ('Select Change Type') : (changeType === 1 ? ('Rises') : (changeType === 2 ? ('Rises or Drops') : ('Drops')))}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item key="1" eventKey="1">Rises</Dropdown.Item>
                                                <Dropdown.Item key="2" eventKey="2">Rises or Drops</Dropdown.Item>
                                                <Dropdown.Item key="3" eventKey="3">Drops</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={changePerc} onChange={e => setChangePerc(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Dropdown onSelect={handleSelectAtDate}>
                                            <Dropdown.Toggle variant="secondary" id="selectedAtDate-selection" size="sm">
                                                {Object.keys(selectedAtDate).length === 0 ? ('Select At Date') : (selectedAtDate.name+' '+selectedAtDate.info)}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {atDateList.map((date) => (
                                                    <Dropdown.Item key={date.id} eventKey={date.id}>{date.name} {date.date} {date.info}</Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={volatility} onChange={e => setVolatility(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={risk} onChange={e => setRisk(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={minWinProb} onChange={e => setMinWinProb(e.target.value)}/>
                                    </td>
                                    <td>
                                        {Object.keys(selectedExpiry).length === 0 ? (
                                            <Button variant="secondary" size="sm" onClick={onFindBuyTrade} disabled>Find Trades</Button>
                                        ) : ( spinnerFindTrade ? (
                                                <Button variant="secondary" size="sm" disabled>
                                                    <Spinner animation="border" variant="light" size="sm"/>
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" size="sm" onClick={onFindBuyTrade}>Find Trades</Button>
                                            )
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default BuyOptionsComboFinder
