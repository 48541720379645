import { Table, Card } from 'react-bootstrap'

import AlertingBadge from './AlertingBadge'

import useBasicMarketData from "../hooks/useBasicMarketData"


const UnderlyingPanel = ( { selectedUnderlying } ) => {

    const basicMarketdData = useBasicMarketData(selectedUnderlying)
    const timeState = new Date().addHours(basicMarketdData.timeDifference)

    return (
        <Card className="h-100">
            <Card.Header>Market Data for {Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.symbol) : ('N/A')}</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th className='text-center' style={{width: "10%"}}>Status</th>
                                <th className='text-end' style={{width: "10%"}}>Bid Size</th>
                                <th className='text-center' style={{width: "10%"}}>Bid</th>
                                <th className='text-center' style={{width: "10%"}}>Ask</th>
                                <th className='text-start' style={{width: "10%"}}>Ask Size</th>
                                <th className='text-center' style={{width: "10%"}}>Last</th>
                                <th className='text-center' style={{width: "10%"}}>Close</th>
                                <th className='text-center' style={{width: "10%"}}>Local Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='align-middle'>
                                    {Object.keys(basicMarketdData).length !== 0 ? (
                                        basicMarketdData.status === 'Open' ? (
                                            <AlertingBadge value={basicMarketdData.status} alertingLevel="success" unit=""></AlertingBadge>
                                        ) : ( basicMarketdData.status === 'AfterHours' ? (
                                                <AlertingBadge value={basicMarketdData.status} alertingLevel="warning" unit=""></AlertingBadge>
                                                ) : (
                                                    <AlertingBadge value={basicMarketdData.status} alertingLevel="danger" unit=""></AlertingBadge>
                                                )
                                            )
                                        ) : null
                                    }
                                </td>
                                <td className='text-end align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.bidSize) : null}</td>
                                <td className='text-center align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.bid) : null}</td>
                                <td className='text-center align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.ask) : null}</td>
                                <td className='text-start align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.askSize) : null}</td>
                                <td className='text-center align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.last) : null}</td>
                                <td className='text-center align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.close) : null}</td>
                                <td className='text-center align-middle'>
                                    {timeState !== 0 ? (
                                        timeState.toLocaleString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                        })
                                    ) : null}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default UnderlyingPanel