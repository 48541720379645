import Color from 'color';

const primary = Color('#4e888f');
const primary75 = primary.lighten(0.25);
const primary50 = primary.lighten(0.50);
const primary25 = primary.lighten(0.75);

const selectCustomTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        primary: primary.hex(),
        primary75: primary75.hex(),
        primary50: primary50.hex(),
        primary25: primary25.hex(),
    },
});

  export default selectCustomTheme
