import companyLogo from '../img/logo.png';
import { Container, Nav, Navbar, Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';

import logoutRequest from "../utilities/logoutRequest"

import TopMenuAccount from './TopMenuAccount';


const TopMenu = ( {setUserInfo, serverStatus, account, nextEarning} ) => {
    const navigate = useNavigate();

    const onLogout = async () => {
        logoutRequest(setUserInfo)
        navigate("/")
    }

    return (
        <Navbar bg="secondary" data-bs-theme="dark" expand="lg" sticky="top">
            <Container className="mx-3">
                <Navbar.Brand href="/positions">
                    <img
                        alt=""
                        src={companyLogo}
                        height="80"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Nav defaultActiveKey="0">
                    <Nav.Link as={Link} to="/positions">Positions</Nav.Link>
                    <Nav.Link as={Link} to="/selling">Selling</Nav.Link>
                    <Nav.Link as={Link} to="/buying">Buying</Nav.Link>
                    {/* <Nav.Link as={Link} to="/volatility">Volatility</Nav.Link> */}
                    <Nav.Link as={Link} to="/trades">Trades</Nav.Link>
                    <Nav.Link as={Link} to="/data">Static Data</Nav.Link>
                    <Nav.Link as={Link} onClick={onLogout}>Logout</Nav.Link>
                </Nav>
            </Container>
            <Container className="mx-3">
                <Navbar.Text>
                    <Table className="TopMenu-table" size='sm'>
                         {Object.keys(nextEarning).length !== 0 ? (
                            <>
                                <thead>
                                    <tr>
                                        {nextEarning['dates'].map((date, index) => (
                                            <th key={index} className='TopMenu-table align-middle'>{date}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {nextEarning['symbols'].map((symbols, indexTD) => (
                                            <td key={indexTD} className='TopMenu-table align-middle text-center'>{symbols}</td>
                                        ))}
                                    </tr>
                                </tbody>
                            </>
                        ) : null}
                    </Table>
                </Navbar.Text>
            </Container>
            <Container className="mx-3 justify-content-end">
                <Navbar.Text>
                    <TopMenuAccount serverStatus={serverStatus} account={account}></TopMenuAccount>
                </Navbar.Text>
            </Container>
        </Navbar>
    );
}

export default TopMenu;