import { Row, Col } from 'react-bootstrap'

import PositionsSummary from '../components/PositionsSummary'
import PositionsDetail from'../components/PositionsDetail'
import PositionsClosed from '../components/PositionsClosed'


const Positions = ( {positions} ) => {
    
    return (
        <>
            { Object.keys(positions).length !== 1 ? (
                <>
                    <Row>
                        <Col>
                            <PositionsSummary positions={positions}></PositionsSummary>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PositionsDetail positions={positions}></PositionsDetail>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PositionsClosed positions={positions}></PositionsClosed>
                        </Col>
                    </Row>
                </>
                ) : null
            }
        </>
    )
}

export default Positions