import { useEffect } from 'react';

function useAlertBanner(alert, expiredAlert, setExpiredAlert) {
    useEffect(() => {
        // console.log('useAlertBanner : ' + alert['id'] + ' ' + alert['message'])

        const toRef = setTimeout(() => {
            setExpiredAlert(expiredAlert => [...expiredAlert, alert['id']]);
            console.log('Timeout useAlertBanner : ' + alert['id'])
        }, 3000);

        return () => {
            clearTimeout(toRef);
        };
    // eslint-disable-next-line
    }, []);
}

export default useAlertBanner;
