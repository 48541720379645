import { Currency } from 'react-intl-number-format'
import { Table } from 'react-bootstrap'

import AlertingBadge from "./AlertingBadge"


const TopMenuAccount = ( {serverStatus, account} ) => {
    return (
        <>
            <Table className="TopMenu-table" size='sm'>
                <thead>
                    <tr>
                        <th className='TopMenu-table text-center'>Balance</th>
                        <th className='TopMenu-table text-center'>This Month</th>
                        <th className='TopMenu-table text-center'>Last Month</th>
                        <th className='TopMenu-table text-center'>This Year</th>
                        <th className='TopMenu-table text-center'>All Time</th>
                        <th className='TopMenu-table text-center'>Server Status</th>
                        <th className='TopMenu-table text-center'>Account</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='TopMenu-table align-middle text-center'>
                            <div style={{"fontSize": "1.1rem"}}>
                                <Currency>{account.TotalCashValue}</Currency>
                            </div>
                        </td>
                        <td className='TopMenu-table align-middle text-center'>
                            {account.perfThisMonth > 0 ? (
                                <AlertingBadge value={account.perfThisMonth} alertingLevel="success" unit="perc"></AlertingBadge>
                            ) : (
                                    <AlertingBadge value={account.perfThisMonth} alertingLevel="danger" unit="perc"></AlertingBadge>
                                )
                            }  
                        </td>
                        <td className='TopMenu-table align-middle text-center'>
                            {account.perfLastMonth > 0 ? (
                                <AlertingBadge value={account.perfLastMonth} alertingLevel="success" unit="perc"></AlertingBadge>
                            ) : (
                                    <AlertingBadge value={account.perfLastMonth} alertingLevel="danger" unit="perc"></AlertingBadge>
                                )
                            }  
                        </td>
                        <td className='TopMenu-table align-middle text-center'>
                            {account.perfThisYear > 0 ? (
                                <AlertingBadge value={account.perfThisYear} alertingLevel="success" unit="perc"></AlertingBadge>
                            ) : (
                                    <AlertingBadge value={account.perfThisYear} alertingLevel="danger" unit="perc"></AlertingBadge>
                                )
                            }
                        </td>
                        <td className='TopMenu-table align-middle text-center'>
                            {account.perfAllTime > 0 ? (
                                <AlertingBadge value={account.perfAllTime} alertingLevel="success" unit="perc"></AlertingBadge>
                            ) : (
                                    <AlertingBadge value={account.perfAllTime} alertingLevel="danger" unit="perc"></AlertingBadge>
                                )
                            }
                        </td>
                        <td className='TopMenu-table align-middle text-center'>
                            {serverStatus.value === 2 ? (
                                <AlertingBadge value={serverStatus.text} alertingLevel="success" unit=""></AlertingBadge>
                            ) : ( serverStatus.value === 1 ? (
                                    <AlertingBadge value={serverStatus.text} alertingLevel="warning" unit=""></AlertingBadge>
                                ) : (
                                        <AlertingBadge value={serverStatus.text} alertingLevel="danger" unit=""></AlertingBadge>
                                    )
                                )
                            }
                        </td>
                        <td className='TopMenu-table align-middle text-center'>{account.accountName}</td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default TopMenuAccount