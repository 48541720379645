import { useState } from "react"
import { Row, Col } from 'react-bootstrap'

import UnderlyingPanel from '../components/UnderlyingPanel'
import SelectUnderlyingAndExpiry from "../components/SelectUnderlyingAndExpiry"
import TradesDisplay from "../components/TradesDisplay"
import BuyOptionsComboFinder from '../components/BuyOptionsComboFinder'
import StandardDeviation from "../components/StandardDeviation"


const BuyOptions = () => {
    const [selectedUnderlying, setSelectedUnderlying] = useState({})
    const [selectedExpiry, setSelectedExpiry] = useState({})
    const [selectedAtDate, setSelectedAtDate] = useState({})
    const [iv, setIV] = useState({'expiry': 20000101, 'value': -1})
    const [trades, setTrades] = useState([])

    return (
        <>
            <Row>
                <Col lg={3}>
                    <SelectUnderlyingAndExpiry selectedUnderlying={selectedUnderlying}
                                        setSelectedUnderlying={setSelectedUnderlying}
                                        selectedExpiry={selectedExpiry}
                                        setSelectedExpiry={setSelectedExpiry}
                                        setIV={setIV}>
                    </SelectUnderlyingAndExpiry>
                </Col>
                <Col>
                    <Row>
                        {Object.keys(selectedUnderlying).length !== 0 ? (
                            <UnderlyingPanel selectedUnderlying={selectedUnderlying}>
                            </UnderlyingPanel>
                        ) : null}
                    </Row>
                    <Row>
                        {Object.keys(selectedExpiry).length !== 0 ? (
                                <StandardDeviation selectedUnderlying={selectedUnderlying}
                                                    iv={iv}
                                                    selectedAtDate={selectedAtDate} >
                                </StandardDeviation>
                        ) : null}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    {Object.keys(selectedExpiry).length !== 0 ? (
                        <BuyOptionsComboFinder selectedUnderlying={selectedUnderlying}
                                                selectedExpiry={selectedExpiry}
                                                iv={iv}
                                                setIV={setIV}
                                                selectedAtDate={selectedAtDate}
                                                setSelectedAtDate={setSelectedAtDate}
                                                setTrades={setTrades}>
                        </BuyOptionsComboFinder>
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col>
                    {Object.keys(selectedExpiry).length !== 0 ? (
                        <TradesDisplay trades={trades}>
                        </TradesDisplay>
                    ) : null}
                </Col>
            </Row>
        </>
    )
}

export default BuyOptions