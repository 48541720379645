import { Table, Card } from 'react-bootstrap'
import { Currency } from 'react-intl-number-format'

import AlertingBadge from './AlertingBadge'


const PositionsClosed = ( {positions} ) => {
    return (
        <Card>
            <Card.Header>Closed Positions Details</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th className='text-start positions-details-table' style={{width: "5%"}}>Trade ID</th>
                                <th className='text-start positions-details-table'>Trade Date</th>
                                <th className='text-start positions-details-table'>Close date</th>
                                <th className='text-start positions-details-table'>Symbol</th>
                                <th className='text-start positions-details-table'>Definition</th>
                                <th className='text-start positions-details-table'>Qty</th>
                                <th className='text-start positions-details-table'>PnL</th>
                                <th className='text-start positions-details-table'>Return</th>
                                <th className='text-start positions-details-table'>Fees</th>
                                <th className='text-start positions-details-table'>Margin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(positions).length !== 0 ? (
                                positions.ClosedTradesList.map((trade) => (     
                                    <tr key={trade.id}>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}>{trade.id}</td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}>{trade.tradeDateTime}</td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}>{trade.closeTradeDateTime}</td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}>{trade.instrument.symbol}</td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}>{trade.instrument.definiton}</td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}>{trade.quantity}</td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}>
                                            {trade.PnL > 0 ? (
                                                <AlertingBadge value={trade.PnL} alertingLevel="success" unit="cur"></AlertingBadge>
                                            ) : (
                                                <AlertingBadge value={trade.PnL} alertingLevel="danger" unit="cur"></AlertingBadge>
                                            )
                                            }
                                        </td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}>{trade.perf} %</td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}><Currency>{trade.fees}</Currency></td>
                                        <td className='text-start positions-details-table' id={trade.status === 'SimulationClosed' ? 'Simulation-Trade' : ''}><Currency>{trade.marginUsage}</Currency></td>
                                    </tr>
                                ))
                            ) : null}
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default PositionsClosed