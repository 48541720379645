import { useEffect } from 'react';

import genericRequest from '../utilities/genericRequest';


function useStandardDeviation(selectedUnderlying, selectedAtDate, iv, setStandardDeviationData) {

    useEffect(() => {
        const interval = setInterval(() => {
            if (iv.value > 0 && Object.keys(selectedAtDate).length !== 0) {
                genericRequest('StandardDeviation', {'symbol': selectedUnderlying.symbol, 'secType': selectedUnderlying.secType, 'date': selectedAtDate.name, 'volatility': iv.value}, setStandardDeviationData)
            }
        }, 1000);

        return () => clearInterval(interval);
    })
}

export default useStandardDeviation;
