import { useEffect } from 'react';

import httpInstance from '../utilities/httpClient';


function useFeed(alertsList, setAlertsList) {
    const fetchFeed = () => {
        httpInstance.get('/getFeedBack')
        .then(function (response) {
            if (Object.keys(response.data).length !== 0 ) {
                let temp = {}
                for (let index = 0; index < response.data.length; index++) {
                    temp[response.data[index]['id']] = response.data[index]
                }
                setAlertsList(Object.assign({}, alertsList, temp))
            }
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                }
            }
            console.log(error);
        });
    }

    // call only once at startup
    useEffect(() => {
        fetchFeed()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchFeed();
        }, 1000);

        return () => clearInterval(interval);
    })
}

export default useFeed;
