import httpInstance from "./httpClient"
import UserProfile from "./userProfile"
import getCookie from "./getCookie"


const logoutRequest = async (setUserInfo) => {
    await httpInstance.post("/logout", {}, {
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    .then(function (response) {
        console.log('Successfully logging out : ' + response)
        UserProfile.clearUser();
        setUserInfo({})
    })
    .catch(function (error) {
        console.log('Error logging out : ' + error)
    });
}

export default logoutRequest