import { useEffect } from "react"

import httpInstance from '../utilities/httpClient';
import UserProfile from '../utilities/userProfile';
import largeCapEarningDatesRequest from '../utilities/largeCapEarningDatesRequest'


function useApp(userInfo, setUserInfo, serverStatus, setServerStatus, account, setAccount, positions, setPositions, orders, setOrders, executions, setExecutions, setNextEarning) {

    const fetchStatus = () => {
        if (Object.keys(userInfo).length !== 0) {
            httpInstance.get('/getDynamicData')
            .then(function (response) {
                if (Object.keys(response.data).length !== 0) {
                    
                    if (response.data.GatewayStatus['value'] !== serverStatus['value']){
                        console.log('Update GatewayStatus : ' + response.data.GatewayStatus['value'])
                        setServerStatus(response.data.GatewayStatus);
                    }

                    if (response.data.Account['lastBalanceLoad'] !== account['lastBalanceLoad'] || response.data.Account['lastPerfLoad'] !== account['lastPerfLoad']){
                        console.log('Update Account Balance : ' + response.data.Account['lastBalanceLoad'])
                        console.log('Update Account Perf : ' + response.data.Account['lastPerfLoad'])
                        setAccount(response.data.Account);
                    }

                    // We always update positon
                    setPositions(response.data.Position);

                    if (response.data.Orders['lastOrdersUpdate'] !== orders['lastOrdersUpdate']){
                        console.log('Update Orders : ' + response.data.Orders['lastOrdersUpdate'])
                        setOrders(response.data.Orders);
                    }

                    if (response.data.Executions['lastExecutionsUpdate'] !== executions['lastExecutionsUpdate']){
                        console.log('Update Executions : ' + response.data.Executions['lastExecutionsUpdate'])
                        setExecutions(response.data.Executions);
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log('User Logged Out')
                        UserProfile.clearUser()
                        setUserInfo({})
                    }
                }
                console.log("Server is Down : " + error);
                console.log(error);
                setServerStatus({'value': 0, 'text': 'Down', 'time': '1900-01-01 00:00:00'});
            })
        }
    }

    // call only once at startup
    useEffect(() => {
        // Recover previous session
        if (Object.keys(UserProfile.getUser()).length !== 0 && Object.keys(userInfo).length === 0) {
            setUserInfo(UserProfile.getUser())
            console.log('Session Recovered !')
        }
        fetchStatus()
        largeCapEarningDatesRequest(setNextEarning)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, setUserInfo])
    
    useEffect(() => {
        const interval = setInterval(() => {
            fetchStatus()
        }, 1000);

        return () => clearInterval(interval);
    })

}

export default useApp;