import { useEffect } from 'react';

import httpInstance from '../utilities/httpClient';
import getCookie from "../utilities/getCookie"


function useIV(selectedUnderlying, selectedExpiry, setIV) {
    const fetchIV = () => {
        httpInstance.post('/getExpiryIV', {
            'symbol': selectedUnderlying.symbol,
            'secType': selectedUnderlying.secType,
            'expiry': selectedExpiry.name,
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setIV(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                }
            }
            console.log(error);
        });
    }

    // call only once at startup
    useEffect(() => {
        fetchIV()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchIV();
        }, 1000);

        return () => clearInterval(interval);
    })
}

export default useIV;
