import { useState } from "react"
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from './routes/Layout'
import SellOptions from './routes/SellOptions'
import BuyOptions from './routes/BuyOptions'
import Positions from './routes/Positions'
// import Volatility from './routes/Volatility'
import Trades from './routes/Trades'
import StaticData from "./routes/StaticData";
import Login from './routes/Login'
import NoPage from './routes/NoPage'

import useApp from "./hooks/useApp";


// eslint-disable-next-line
Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}


const App = () => {
    const [userInfo, setUserInfo] = useState({})
    const [serverStatus, setServerStatus] = useState({'value': 0, 'text': 'Down', 'time': '1900-01-01 00:00:00'})
    const [account, setAccount] = useState({'TotalCashValue': 0, 'perfThisMonth': 0, 'perfLastMonth': 0, 'perfThisYear': 0, 'perfAllTime': 0, 'lastAccountLoad': '1900-01-01 00:00:00'})
    const [positions, setPositions] = useState({'lastPositionUpdate': '1900-01-01 00:00:00'})
    const [orders, setOrders] = useState({'lastOrdersUpdate': '1900-01-01 00:00:00', 'list': []})
    const [executions, setExecutions] = useState({'lastExecutionsUpdate': '1900-01-01 00:00:00', 'list': []})
    const [nextEarning, setNextEarning] = useState({})

    useApp(userInfo, setUserInfo, serverStatus, setServerStatus, account, setAccount, positions, setPositions, orders, setOrders, executions, setExecutions, setNextEarning)

    return (
        <>
            <Routes>
                {Object.keys(userInfo).length === 0 ? (
                    <Route path="/">
                        <Route index element={<Navigate to="/login"></Navigate>} />
                        <Route path="login" element={<Login setUserInfo={setUserInfo}></Login>} />
                        <Route path="*" element={<Navigate to="/login"></Navigate>} />
                    </Route>
                ) : (
                    <Route path="/" element={<Layout setUserInfo={setUserInfo} serverStatus={serverStatus} account={account} nextEarning={nextEarning}></Layout> }>
                        <Route index element={<Navigate to="/positions"></Navigate>} />
                        <Route path="login" element={<Navigate to="/positions"></Navigate>} />
                        <Route path="positions" element={<Positions positions={positions}></Positions>} />
                        <Route path="selling" element={<SellOptions></SellOptions>} />
                        <Route path="buying" element={<BuyOptions></BuyOptions>} />
                        <Route path="trades" element={<Trades orders={orders} executions={executions}></Trades>} />
                        <Route path="data" element={<StaticData></StaticData>} />
                        {/* <Route path="volatility" element={
                            <Volatility serverStatus={serverStatus}
                                    underlyings={underlyings}
                                    onSelectUnderlying={onSelectUnderlying}
                                    selectedUnderlying={selectedUnderlying}
                                    basicMarketdData={basicMarketdData}
                                    timeState={timeState}>
                            </Volatility>
                        } /> */}
                        <Route path="*" element={<NoPage />} />
                    </Route>
                )}
            </Routes>
        </>
    )
}

export default App