import { useState } from "react"
import { Card, Row, Form, Button, Table, Spinner } from 'react-bootstrap'

import useIV from "../hooks/useIV"
import useRiskFreeRate from "../hooks/useRiskFreeRate"

import genericRequestWithSpinner from "../utilities/genericRequestWithSpinner"


const SellOptionsComboFinder = ( { selectedUnderlying, selectedExpiry, iv, setIV, setTrades } ) => {
    const [volatility, setVolatility] = useState(0)
    const [putRisk, setPutRisk] = useState(0)
    const [callRisk, setCallRisk] = useState(0)
    const [marginTarget, setMarginTarget] = useState(0)
    const [riskFreeRate, setRiskFreeRate] = useState({})
    const [spinnerFindTrade, setspinnerFindTrade] = useState(false)

    useIV(selectedUnderlying, selectedExpiry, setIV)
    useRiskFreeRate(setRiskFreeRate)

    const onFindSellTrade = async () => {
        console.log('onFindSellTrade')
        setspinnerFindTrade(true)

        const requestData = {
            'symbol': selectedUnderlying.symbol,
            'secType': selectedUnderlying.secType,
            'currency': selectedUnderlying.currency,
            'expiry': selectedExpiry.name,
            'put_risk': putRisk,
            'call_risk': callRisk,
            'max_margin': marginTarget,
            'volatility': volatility
        }
        genericRequestWithSpinner('FindOptionsSellingTrade', requestData, setTrades, setspinnerFindTrade)
    }

    return (
        <Card className="h-100">
            <Card.Header>Combo Finder : Selling Options for Expiry {selectedExpiry.name}</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Row className="combo-finder-selling-options-menu">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th>Current IV (%)</th>
                                    <th>Risk Free Rate (%)</th>
                                    <th>Put Risk (%)</th>
                                    <th>Call Risk (%)</th>
                                    <th>Margin Target ($)</th>
                                    <th>Volatility (%)</th>
                                    <th  style={{width: "10%"}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key="0">
                                    <td>
                                        <Form.Control size="sm" placeholder={iv.value} disabled/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" placeholder={riskFreeRate.value} disabled/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={putRisk} onChange={e => setPutRisk(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={callRisk} onChange={e => setCallRisk(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={marginTarget} onChange={e => setMarginTarget(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={volatility} onChange={e => setVolatility(e.target.value)}/>
                                    </td>
                                    <td>
                                        {Object.keys(selectedExpiry).length === 0 ? (
                                            <Button variant="secondary" size="sm" onClick={onFindSellTrade} disabled>Find Trades</Button>
                                        ) : ( spinnerFindTrade ? (
                                                <Button variant="secondary" size="sm" disabled>
                                                    <Spinner animation="border" variant="light" size="sm"/>
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" size="sm" onClick={onFindSellTrade}>Find Trades</Button>
                                            )
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Card.Text>
            </Card.Body>
            
        </Card>
    )
}

export default SellOptionsComboFinder
