import { useState } from "react"
import { Table, Card, Button } from 'react-bootstrap'
import { Currency } from 'react-intl-number-format'

import AlertingBadge from './AlertingBadge'
import ModalUpdateTrade from './ModalUpdateTrade'
import ModalCloseTrade from './ModalCloseTrade'
import round2decimals from "../utilities/round2decimals"


const PositionsDetail = ( { positions } ) => {
    const [selectedTradeToUpdate, setSelectedTradeToUpdate] = useState({})
    const [selectedTradeToClose, setSelectedTradeToClose] = useState({})

    return (
        <Card>
            <Card.Header>Positions Details</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm" hover>
                        <thead>
                            <tr>
                                <th className='positions-details-table'>Trade ID</th>
                                <th className='positions-details-table'>Trade Date</th>
                                <th className='positions-details-table'>Status</th>
                                <th className='positions-details-table'>Symbol</th>
                                <th className='positions-details-table'>Und/Prc</th>
                                <th className='positions-details-table'>Definition</th>
                                <th className='positions-details-table'>Qty</th>
                                <th className='positions-details-table'>Open Price</th>
                                <th className='positions-details-table'>Current Price</th>
                                <th className='positions-details-table'>PnL</th>
                                <th className='positions-details-table'>Net Price</th>
                                <th className='positions-details-table'>Margin</th>
                                <th className='positions-details-table'>Days Left</th>
                                <th className='positions-details-table'>Target Date</th>
                                <th className='positions-details-table'>Target IV</th>
                                <th className='positions-details-table special-table-column-width'>Deviation</th>
                                <th className='positions-details-table'>Curr IV</th>
                                <th className='positions-details-table'>Curr Win Prob</th>
                                <th className='positions-details-table'>Return</th>
                                <th className='positions-details-table'>Fees</th>
                                <th className='positions-details-table'>Init IV</th>
                                <th className='positions-details-table'>Init Win Prob</th>
                                <th className='positions-details-table'></th>
                                <th className='positions-details-table'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(positions).length !== 0 ? (
                                positions.TradesList.map((trade, index) => (
                                    // <tr key={trade.id} onClick={() => onSelectUpdateTrade(index)}>
                                    <tr key={trade.id}>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.id}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.tradeDateTime}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.status}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.instrument.symbol}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}><Currency>{trade.instrument.cur_underlying_price}</Currency></td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.instrument.definiton}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.quantity}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}><Currency>{trade.instrument.openPrice}</Currency></td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}><Currency>{trade.instrument.currentPrice}</Currency></td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>
                                            {trade.PnL > 0 ? (
                                                <AlertingBadge value={trade.PnL} alertingLevel="success" unit="cur"></AlertingBadge>
                                            ) : (
                                                    <AlertingBadge value={trade.PnL} alertingLevel="danger" unit="cur"></AlertingBadge>
                                                )
                                            }
                                        </td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}><Currency>{trade.netPrice}</Currency></td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}><Currency>{trade.marginUsage}</Currency></td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.instrument.daysToExpiry}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.targetDate}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{round2decimals(trade.targetVolatility)} %</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.instrument.deviation}</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{round2decimals(trade.instrument.currentExpiryIV)} %</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>
                                            {trade.instrument.openPrice > 0 ? (
                                                <AlertingBadge value={round2decimals(trade.instrument.currentProbability)} alertingLevel="info" unit="perc"></AlertingBadge>
                                            ) : (
                                                trade.instrument.currentProbability > 0.85 ? (
                                                <AlertingBadge value={round2decimals(trade.instrument.currentProbability)} alertingLevel="success" unit="perc"></AlertingBadge>
                                            ) : trade.instrument.currentProbability > 0.75 ?  (
                                                <AlertingBadge value={round2decimals(trade.instrument.currentProbability)} alertingLevel="warning" unit="perc"></AlertingBadge>
                                            ) : (
                                                <AlertingBadge value={round2decimals(trade.instrument.currentProbability)} alertingLevel="danger" unit="perc"></AlertingBadge>
                                            ))}
                                        </td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{trade.perf} %</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}><Currency>{trade.fees}</Currency></td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{round2decimals(trade.initialExpiryVolatility)} %</td>
                                        <td className='positions-details-table' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>{round2decimals(trade.instrument.probability)} %</td>
                                        <td className='positions-details-table-no-padding' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>
                                            <Button variant="secondary" size="ssm" onClick={() => setSelectedTradeToUpdate(positions.TradesList[index])}><i className="bi bi-pen icon-60"></i></Button>
                                        </td>
                                        <td className='positions-details-table-no-padding' id={trade.status === 'Pending' || trade.status === 'PendingClosed' ? 'Pending-Trade' : (trade.status === 'SimulationOpen' ? 'Simulation-Trade' : '')}>
                                            <Button variant="secondary" size="ssm" onClick={() => setSelectedTradeToClose(positions.TradesList[index])}><i className="bi bi-x-circle icon-60"></i></Button>
                                        </td>
                                    </tr>
                                ))
                            ) : null}
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
            {Object.keys(selectedTradeToUpdate).length !== 0 ? (
                <ModalUpdateTrade selectedTradeToUpdate={selectedTradeToUpdate} setSelectedTradeToUpdate={setSelectedTradeToUpdate}></ModalUpdateTrade>
            ) : null}
            {Object.keys(selectedTradeToClose).length !== 0 ? (
                <ModalCloseTrade selectedTradeToClose={selectedTradeToClose} setSelectedTradeToClose={setSelectedTradeToClose}></ModalCloseTrade>
            ) : null}
        </Card>
    )
}

export default PositionsDetail