import { useState } from "react"
import { Row, Col } from 'react-bootstrap'

import UnderlyingPanel from '../components/UnderlyingPanel'
import SellOptionsComboFinder from '../components/SellOptionsComboFinder'
import SelectUnderlyingAndExpiry from "../components/SelectUnderlyingAndExpiry"
import TradesDisplay from "../components/TradesDisplay"
import StandardDeviation from "../components/StandardDeviation"


const SellOptions = () => {
    const [selectedUnderlying, setSelectedUnderlying] = useState({})
    const [optionsExpiries, setOptionsExpiries] = useState([])
    const [selectedExpiry, setSelectedExpiry] = useState({})
    const [iv, setIV] = useState({'expiry': 20000101, 'value': -1})
    const [trades, setTrades] = useState([])
    
    return (
        <>
            <Row>
                <Col lg={3}>
                    <SelectUnderlyingAndExpiry selectedUnderlying={selectedUnderlying}
                                        setSelectedUnderlying={setSelectedUnderlying}
                                        optionsExpiries={optionsExpiries}
                                        setOptionsExpiries={setOptionsExpiries}
                                        selectedExpiry={selectedExpiry}
                                        setSelectedExpiry={setSelectedExpiry}
                                        setIV={setIV}>
                    </SelectUnderlyingAndExpiry>
                </Col>
                <Col>
                <Row>
                    {Object.keys(selectedUnderlying).length !== 0 ? (
                        <UnderlyingPanel selectedUnderlying={selectedUnderlying}>
                        </UnderlyingPanel>
                    ) : null}
                    </Row>
                    <Row>
                        {Object.keys(selectedExpiry).length !== 0 ? (
                            <StandardDeviation selectedUnderlying={selectedUnderlying}
                                                iv={iv}
                                                selectedAtDate={selectedExpiry} >
                            </StandardDeviation>
                    ) : null}
                    </Row>
                </Col>
            </Row>
            <Row>
                {Object.keys(selectedExpiry).length !== 0 ? (
                    <SellOptionsComboFinder selectedUnderlying={selectedUnderlying}
                                            selectedExpiry={selectedExpiry}
                                            iv={iv}
                                            setIV={setIV}
                                            setTrades={setTrades}>
                    </SellOptionsComboFinder>
                ) : null}
            </Row>
            <Row>
                {Object.keys(selectedExpiry).length !== 0 ? (
                    <TradesDisplay trades={trades}>
                    </TradesDisplay>
                ) : null}
            </Row>
        </>
    )
}

export default SellOptions
