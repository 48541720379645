import { Form, Button, Table, Modal, InputGroup, Row, Col } from 'react-bootstrap'

import genericRequest from '../utilities/genericRequest';


const ModalUpdateTrade = ( { selectedTradeToUpdate, setSelectedTradeToUpdate } ) => {
    const handleClose = () => setSelectedTradeToUpdate({});

    const onChangePrice = (event) => {
        selectedTradeToUpdate.instrument.legs[event.target.id].openPrice = event.target.value
    }

    const onChangeFees = (event) => {
        selectedTradeToUpdate.instrument.legs[event.target.id].fees = event.target.value
    }

    const onChangeQuantity = (event) => {
        selectedTradeToUpdate.quantity = event.target.value
    }

    const handleUpdateTrade = () => {
        console.log('handleUpdateTrade')
        const requestData = {
            'action': 'UpdateTrade',
            'data': selectedTradeToUpdate
        }
        genericRequest('Trade', requestData, setSelectedTradeToUpdate)
        setSelectedTradeToUpdate({});
    }

    return (
        <div>
            <Modal show="true" size="lg" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {"Trade ID : " + selectedTradeToUpdate.id + " - " + selectedTradeToUpdate.instrument.definiton}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Row className='align-middle'>
                        <Table size="sm" className='rounded-4 overflow-hidden mb-0'>
                            <thead className="table-primary">
                                <tr>
                                    <th className='align-middle'>Status</th>
                                    <th className='align-middle'>Expiry</th>
                                    <th className='align-middle'>Side</th>
                                    <th className='align-middle'>Type</th>
                                    <th className='align-middle'>Strike</th>
                                    <th className='align-middle' style={{width: "15%"}}>Open Price</th>
                                    <th className='align-middle' style={{width: "15%"}}>Open Fees</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedTradeToUpdate.instrument.legs.map((leg, index) => (
                                    <tr key={index}>
                                        <td className='align-middle'>{leg.status}</td>
                                        <td className='align-middle'>{leg.expiry}</td>
                                        <td className='align-middle'>{leg.side}</td>
                                        <td className='align-middle'>{leg.type}</td>
                                        <td className='align-middle'>{leg.strike}</td>
                                        <td className='align-middle'>
                                            <Form.Group controlId={index}>
                                                <Form.Control size="sm" defaultValue={leg.openPrice} onChange={onChangePrice}/>
                                            </Form.Group>
                                        </td>
                                        <td className='align-middle'>
                                            <Form.Group controlId={index}>
                                                <Form.Control size="sm" defaultValue={leg.fees} onChange={onChangeFees}/>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    ))
                                }
                                <tr>
                                    <td colSpan={3} className='align-middle'>
                                        <InputGroup size="sm" className="my-1">
                                            <InputGroup.Text id="quantity">Quantity</InputGroup.Text>
                                            <Form.Control aria-label="quantity" defaultValue={selectedTradeToUpdate.quantity} onChange={onChangeQuantity}/>
                                        </InputGroup>
                                    </td>
                                    <td colSpan={4} className='align-middle'>
                                        <InputGroup size="sm" className="my-1">
                                            <InputGroup.Text id="tradeDateTime">Open Trade Date&Time</InputGroup.Text>
                                            <Form.Control aria-label="tradeDateTime" defaultValue={selectedTradeToUpdate.tradeDateTime} disabled/>
                                        </InputGroup>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col xs={9}></Col>
                        <Col xs={3} className='align-middle text-center mb-3'>
                            <Button variant="primary" onClick={handleUpdateTrade}>
                                Update Trade
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalUpdateTrade
