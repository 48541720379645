import { Form, Button, Table, Modal, InputGroup, Row, Col } from 'react-bootstrap'

import genericRequest from '../utilities/genericRequest';


const ModalBookTrade = ( { selectedTrade, setSelectedTrade } ) => {
    const handleClose = () => setSelectedTrade({});

    const onChangePrice = (event) => {
        selectedTrade.instrument.legs[event.target.id].openPrice = event.target.value
    }

    const onChangeFees = (event) => {
        selectedTrade.instrument.legs[event.target.id].fees = event.target.value
    }

    const onChangeTradeDateTime = (event) => {
        selectedTrade.tradeDateTime = event.target.value
    }

    const handleBookTrade = () => {
        console.log('handleBookTrade')
        const requestData = {
            'action': 'BookTrade',
            'data': selectedTrade
        }
        genericRequest('Trade', requestData, setSelectedTrade)
        setSelectedTrade({});
    }

    const handleBookPendingTrade = () => {
        console.log('handleBookPendingTrade')
        const requestData = {
            'action': 'BookPendingTrade',
            'data': selectedTrade
        }
        genericRequest('Trade', requestData, setSelectedTrade)
        setSelectedTrade({});
    }

    const handleBookSimulation = () => {
        console.log('handleBookSimulation')
        const requestData = {
            'action': 'BookSimulation',
            'data': selectedTrade
        }
        genericRequest('Trade', requestData, setSelectedTrade)
        setSelectedTrade({});
    }
    
    const handleExecuteTrade = () => {
        console.log('handleExecuteTrade')
        const requestData = {
            'action': 'ExecuteTrade',
            'data': selectedTrade
        }
        genericRequest('Trade', requestData, setSelectedTrade)
        setSelectedTrade({});
    }

    return (
        <div>
            <Modal show="true" size="lg" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedTrade.quantity} {selectedTrade.instrument.definiton} @ {selectedTrade.instrument.openPrice}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Row className='align-middle'>
                        <Table size="sm" className='rounded-4 overflow-hidden mb-0'>
                            <thead className="table-primary">
                                <tr>
                                    <th className='align-middle'>Expiry</th>
                                    <th className='align-middle'>Side</th>
                                    <th className='align-middle'>Type</th>
                                    <th className='align-middle'>Strike</th>
                                    <th className='align-middle' style={{width: "15%"}}>Price</th>
                                    <th className='align-middle' style={{width: "15%"}}>Fees</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedTrade.instrument.legs.map((leg, index) => (
                                    <tr key={index}>
                                        <td className='align-middle'>{leg.expiry}</td>
                                        <td className='align-middle'>{leg.side}</td>
                                        <td className='align-middle'>{leg.type}</td>
                                        <td className='align-middle'>{leg.strike}</td>
                                        <td className='align-middle'>
                                            <Form.Group controlId={index}>
                                                <Form.Control size="sm" defaultValue={leg.openPrice} onChange={onChangePrice}/>
                                            </Form.Group>
                                        </td>
                                        <td className='align-middle'>
                                            <Form.Group controlId={index}>
                                                <Form.Control size="sm" defaultValue={leg.fees} onChange={onChangeFees}/>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    ))
                                }
                                <tr>
                                    <td colSpan={4} className='align-middle'>
                                        <InputGroup size="sm" className="my-1">
                                            <InputGroup.Text id="tradeDateTime">Trade Date & Time</InputGroup.Text>
                                            <Form.Control aria-label="tradeDateTime" defaultValue={selectedTrade.tradeDateTime} onChange={onChangeTradeDateTime}/>
                                        </InputGroup>
                                    </td>
                                    <td colSpan={2} className='align-middle'>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row className='align-middle'>
                        <Col xs={3} className='align-middle text-center mb-3'>
                            <Button variant="secondary" onClick={handleBookTrade}>
                                Book Trade
                            </Button>
                        </Col>
                        <Col xs={3} className='align-middle text-center mb-3'>
                            <Button variant="secondary" onClick={handleBookPendingTrade}>
                                Book Pending Trade
                            </Button>
                        </Col>
                        <Col xs={3} className='align-middle text-center mb-3'>
                            <Button variant="secondary" onClick={handleBookSimulation}>
                                Book Simulation
                            </Button>
                        </Col>
                        <Col xs={3} className='align-middle text-center mb-3'>
                            <Button variant="primary" onClick={handleExecuteTrade}>
                                Execute Trade
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalBookTrade