import { useState } from 'react';

import { Alert } from "react-bootstrap"

import useActiveTrading from "../hooks/useActiveTrading"


const ActiveTradingBanner = () => {
    const [activeTrading, setActiveTrading] = useState({})

    useActiveTrading(setActiveTrading)

    return (
        <>
            {Object.keys(activeTrading).length !== 0 ? (
                <Alert variant={activeTrading['level']}>
                    <div className="text-center">Active Trading : {activeTrading['message']}</div>
                </Alert>
            ) : null}
        </>
    )
}

export default ActiveTradingBanner