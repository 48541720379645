import { useState } from "react"
import { Card, Row, Button, Table } from 'react-bootstrap'
import { Currency } from 'react-intl-number-format'

import ModalBookTrade from './ModalBookTrade'
import round2decimals from "../utilities/round2decimals"


const TradesDisplay = ( { trades } ) => {
    const [selectedTrade, setSelectedTrade] = useState({})

    const onSelectBookTrade = (event) => {
        const id = event.target.id;
        setSelectedTrade(trades[id])
    }

    return (
        <>
            { Object.keys(trades).length !== 0 ? (
                <Card>
                    <Card.Header>Potential Trades</Card.Header>
                    <Card.Body>
                        <Card.Text as="div">
                            <Row>
                                <Table striped size="sm">
                                    <thead className="table-primary">
                                        <tr>
                                            <th className='OptionsComboFinder-results-table align-middle'>Combo</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>PnL</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>Return</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>Quantity</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>Est. Fees</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>Margin</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>Price</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>Deviation</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>Win Prob</th>
                                            <th className='OptionsComboFinder-results-table align-middle'>Legs Probabilities</th>
                                            <th className='OptionsComboFinder-results-table align-middle'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trades.map((trade, index) => (     
                                            <tr key={index}>            
                                                <td className='OptionsComboFinder-results-table align-middle'>{trade.instrument.definiton}</td>
                                                <td className='OptionsComboFinder-results-table align-middle'><Currency>{trade.PnL}</Currency></td>
                                                <td className='OptionsComboFinder-results-table align-middle'>{trade.perf} %</td>
                                                <td className='OptionsComboFinder-results-table align-middle'>{trade.quantity}</td>
                                                <td className='OptionsComboFinder-results-table align-middle'><Currency>{trade.fees}</Currency></td>
                                                <td className='OptionsComboFinder-results-table align-middle'><Currency>{trade.marginUsage}</Currency></td>
                                                <td className='OptionsComboFinder-results-table align-middle'><Currency>{trade.netPrice}</Currency></td>
                                                <td className='OptionsComboFinder-results-table align-middle'>{trade.instrument.deviation}</td>
                                                <td className='OptionsComboFinder-results-table align-middle'>{round2decimals(trade.instrument.probability)} %</td>
                                                <td className='OptionsComboFinder-results-table align-middle'>{trade.instrument.legsProbabilities}</td>
                                                <td className='OptionsComboFinder-results-table align-middle'>
                                                    <Button variant="primary" size="sm" className='OptionsComboFinder-results-table-button' id={index} onClick={onSelectBookTrade}>Book</Button>
                                                </td>
                                            </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </Card.Text>
                    </Card.Body>
                    {Object.keys(selectedTrade).length !== 0 ? (
                        <ModalBookTrade selectedTrade={selectedTrade} setSelectedTrade={setSelectedTrade}></ModalBookTrade>
                    ) : null}
                </Card>
            ) : ("")}
        </>
    )
}

export default TradesDisplay