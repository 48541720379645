import { useState } from "react"
import { Table, Card } from 'react-bootstrap'
import Select from 'react-select';

import underlyingsRequest from '../utilities/underlyingsRequest'
import optionExpiriesRequest from '../utilities/optionExpiriesRequest'
import selectCustomTheme from "../utilities/selectCustomTheme";


const SelectUnderlyingAndExpiry = ( { selectedUnderlying, setSelectedUnderlying, selectedExpiry, setSelectedExpiry, setIV } ) => {
    const [underlyings, setUnderlyings] = useState([])
    const [optionsExpiries, setOptionsExpiries] = useState([])

    if (underlyings.length === 0) {
        underlyingsRequest(setUnderlyings)
    }
    
    const handleSelectUnderlying = (underlying) => {
        console.log('handleSelectUnderlying')
        // console.log(underlying)

        setSelectedUnderlying(underlying)
        setSelectedExpiry({})
        optionExpiriesRequest(underlying.symbol, setOptionsExpiries)
        console.log(optionsExpiries)
    }

    const handleSelectExpiry = (expiry) => {
        console.log('handleSelectExpiry')
        // console.log(expiry)

        setSelectedExpiry(expiry)
        setIV({'expiry': 20000101, 'value': -1})
    }

    
    return (
        <Card className="h-100">
            <Card.Header>{Object.keys(selectedUnderlying).length !== 0 ? (selectedUnderlying.longName) : ('Select Underlying and Expiry')}</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    {Object.keys(underlyings).length !== 0 ? (
                        <>
                            <Table size="sm">
                                <tbody>
                                    <tr key="0">
                                        <td>Underlyings :</td>
                                        <td className='align-middle'>
                                            <Select options={underlyings} 
                                                    placeholder={'Select Underlying...'} 
                                                    onChange={handleSelectUnderlying} 
                                                    theme={selectCustomTheme}/>
                                        </td>
                                    </tr>
                                    <tr key="1">
                                        <td>Expiries : </td>
                                        <td>
                                            <Select options={optionsExpiries} 
                                                    value={Object.keys(selectedExpiry).length === 0 ? null : selectedExpiry} 
                                                    placeholder={'Select Expiry...'} 
                                                    onChange={handleSelectExpiry} 
                                                    theme={selectCustomTheme}/>
                                        </td>
                                    </tr>
                                    {Object.keys(selectedUnderlying).length !== 0 ? (
                                        <tr key="3">
                                            <td>Next Earnings : </td>
                                            <td>{selectedUnderlying.nextEarning}</td>
                                        </tr>)
                                    : null}
                                </tbody>
                            </Table>
                        </>
                    ) : null}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default SelectUnderlyingAndExpiry