import { useState } from 'react';

import TemporaryAlert from './TemporaryAlert';

import useFeed from '../hooks/useFeed';


const Alertbanner = () => {
    const [alertsList, setAlertsList] = useState([])
    const [expiredAlert, setExpiredAlert] = useState('')

    // Feed format
    // {<id>: {'id': <id>, 'level': <info, danger, etcc>, 'message': <data>, 'timeout': True}}
    useFeed(alertsList, setAlertsList)

    if (Object.keys(expiredAlert).length !== 0) {
        let id = expiredAlert.pop() // removes last element from array
        setExpiredAlert([...expiredAlert]) // clone remaining array

        let newAlertsList = alertsList
        delete newAlertsList[id]
        setAlertsList(newAlertsList);
    }

    return (
        <>
            {Object.keys(alertsList).length !== 0 ? (
                Object.keys(alertsList).map((key) => (
                    <TemporaryAlert key={alertsList[key]['id']} alert={alertsList[key]}
                                expiredAlert={expiredAlert}
                                setExpiredAlert={setExpiredAlert}>
                    </TemporaryAlert>  
            ))
            ) : null}
        </>
    )
}

export default Alertbanner