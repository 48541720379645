import { Outlet } from "react-router-dom";

import TopMenu from '../components/TopMenu'
import Alertbanner from "../components/Alertbanner";
import ActiveTradingBanner from "../components/ActiveTradingBanner";


const Layout = ( {setUserInfo, serverStatus, account, nextEarning} ) => {
    return (
        <>
            <TopMenu setUserInfo={setUserInfo} serverStatus={serverStatus} account={account} nextEarning={nextEarning}>
            </TopMenu>
            <div className=".main-frame h-100">
                <ActiveTradingBanner></ActiveTradingBanner>
                <Alertbanner></Alertbanner>
                <Outlet />
            </div>
        </>
    )
}

export default Layout
