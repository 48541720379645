import { Alert } from 'react-bootstrap'

import useAlertBanner from '../hooks/useAlertBanner'


const TemporaryAlert = ( {alert, expiredAlert, setExpiredAlert} ) => {
    useAlertBanner(alert, expiredAlert, setExpiredAlert)

    return (
        <Alert variant={alert['level']}>
            <div className="text-center">{alert['message']}</div>
        </Alert>
    )
}

export default TemporaryAlert