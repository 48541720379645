import { Table, Card } from 'react-bootstrap'
import { Currency } from 'react-intl-number-format'


const ExecutionsList = ( {executions} ) => {
    return (
        <Card>
            <Card.Header>Executions Details</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm" hover>
                        <thead>
                            <tr>
                                <th className='text-start positions-details-table'>Time</th>
                                <th className='text-start positions-details-table'>Exec ID</th>
                                <th className='text-start positions-details-table'>conId</th>
                                <th className='text-start positions-details-table'>Side</th>
                                <th className='text-start positions-details-table'>Shares</th>
                                <th className='text-start positions-details-table'>Price</th>
                                <th className='text-start positions-details-table'>Liquidation</th>
                                <th className='text-start positions-details-table'>Cum Qty</th>
                                <th className='text-start positions-details-table'>Avg Price</th>
                                <th className='text-start positions-details-table'>Order Ref</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(executions).length !== 0 ? (
                                executions['list'].map((execution) => (     
                                    <tr key={execution.execId}>
                                        <td className='text-start positions-details-table'>{execution.time}</td>
                                        <td className='text-start positions-details-table'>{execution.execId}</td>
                                        <td className='text-start positions-details-table'>{execution.conId}</td>
                                        <td className='text-start positions-details-table'>{execution.side}</td>
                                        <td className='text-start positions-details-table'>{execution.shares}</td>
                                        <td className='text-start positions-details-table'><Currency>{execution.price}</Currency></td>
                                        <td className='text-start positions-details-table'>{execution.liquidation}</td>
                                        <td className='text-start positions-details-table'>{execution.cumQty}</td>
                                        <td className='text-start positions-details-table'><Currency>{execution.avgPrice}</Currency></td>
                                        <td className='text-start positions-details-table'>{execution.orderRef}</td>
                                    </tr>
                                ))
                            ) : null}
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ExecutionsList