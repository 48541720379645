import { useState, useEffect } from 'react';

import httpInstance from '../utilities/httpClient';
import getCookie from "../utilities/getCookie"


function useBasicMarketData(selectedUnderlying) {
    const [basicMarketdData, setBasicMarketdData] = useState({})

    const fetchBasicMarketData = () => {
        httpInstance.post('/getBasicMarketData', {
            'symbol': selectedUnderlying.symbol,
            'secType': selectedUnderlying.secType
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setBasicMarketdData(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                }
            }
            console.log(error);
        });
    }

    // call only once at startup
    useEffect(() => {
        fetchBasicMarketData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchBasicMarketData();
        }, 1000);

        return () => clearInterval(interval);
    })

    return basicMarketdData
}

export default useBasicMarketData;
