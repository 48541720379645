import { useState } from "react"
import { Card, InputGroup, Form, Button, Row, Col } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";

import httpInstance from '../utilities/httpClient'
import UserProfile from "../utilities/userProfile"

import Alertbanner from '../components/Alertbanner'


const Login = ( {setUserInfo}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginResponse, setLoginResponse] = useState({})

    const navigate = useNavigate();

    const onLogin = async () => {
        if(email.length === 0){
            setLoginResponse({
                "Result": "danger",
                "Message": "Email can't be blank!"
            })
        }

        if(!email.includes("@westeran.com")){
            setLoginResponse({
                "Result": "danger",
                "Message": "Unauthorized Email!"
            })
        }

        if(password.length === 0){
            setLoginResponse({
                "Result": "danger",
                "Message": "Password can't be blank!"
            })
        }

        if(Object.keys(loginResponse).length === 0){
            try {
                const resp = await httpInstance.post("/login", {
                    email,
                    password,
                });
                UserProfile.setUser(resp.data);
                setUserInfo(resp.data)
                while (Object.keys(UserProfile.getUser()).length === 0) {
                    console.log('wait')
                }
                navigate("/")
            } catch (error) {
                if (error.status === 401) {
                    setLoginResponse({
                        "Result": "danger",
                        "Message": "Invalid Credentials"
                    })
                }
            }
        }
        
        setTimeout(() => {
            setLoginResponse({})
        }, 2000);
    }

    return (
        <>
            {Object.keys(loginResponse).length !== 0 ? (<Alertbanner Response={loginResponse}></Alertbanner>) : null}
            <Row>
                <Col></Col>
                <Col>
                    <Card id='Login-Card'>
                        <Card.Header>Login</Card.Header>
                        <Card.Body>
                            <Card.Text as="div">
                                <Form>
                                    <br/>
                                    <InputGroup>
                                        <InputGroup.Text variant="outline-primary">Email address</InputGroup.Text>
                                        <Form.Control type="email" placeholder="name@westeran.com" onChange={e => setEmail(e.target.value)}/>
                                    </InputGroup>
                                    <br/>
                                    <InputGroup>
                                        <InputGroup.Text variant="outline-primary">Password</InputGroup.Text>
                                        <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
                                    </InputGroup>
                                    <br/>
                                    <Button variant="primary" size="sm" onClick={onLogin}>
                                        Login
                                    </Button>
                                </Form>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
        </>
    )
}

export default Login