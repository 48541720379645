import httpInstance from "./httpClient"
import getCookie from "./getCookie"


const optionExpiriesRequest = async (symbol, setOptionsExpiries) => {
    await httpInstance.post('/getOptionsExpiriesList',{
        'symbol': symbol
    },{
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    .then(function (response) {
        setOptionsExpiries(response.data)
    })
    .catch(function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                console.log('User Logged Out')
            }
        }
        console.log(error);
    });
}

export default optionExpiriesRequest
