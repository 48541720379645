import { Form, Button, Table, Modal, InputGroup, Row, Col } from 'react-bootstrap'

import genericRequest from '../utilities/genericRequest';


const ModalCloseTrade = ( { selectedTradeToClose, setSelectedTradeToClose } ) => {
    const handleClose = () => setSelectedTradeToClose({});

    const onChangePrice = (event) => {
        selectedTradeToClose.instrument.legs[event.target.id].closePrice = event.target.value
    }

    const onChangeFees = (event) => {
        selectedTradeToClose.instrument.legs[event.target.id].additionnalFees = event.target.value
    }

    const onChangeClosedateTime = (event) => {
        selectedTradeToClose.instrument.legs[event.target.id].closeDateTime = event.target.value
    }

    const handleBookClose = () => {
        console.log('handleBookClose')

        for (let i = 0; i < selectedTradeToClose.instrument.legs.length; i++) {
            if (selectedTradeToClose.instrument.legs[i].closePrice === null) {
                selectedTradeToClose.instrument.legs[i].closePrice = selectedTradeToClose.instrument.legs[i].currentPrice
            }

            if (selectedTradeToClose.instrument.legs[i].additionnalFees === null) {
                console.log('update fees')
                console.log(selectedTradeToClose.instrument.legs[i].additionnalFees)
                selectedTradeToClose.instrument.legs[i].additionnalFees = selectedTradeToClose.instrument.legs[i].fees
                console.log(selectedTradeToClose.instrument.legs[i].additionnalFees)
            }
        }

        const requestData = {
            'action': 'BookCloseTrade',
            'data': selectedTradeToClose
        }
        genericRequest('Trade', requestData, setSelectedTradeToClose)
        setSelectedTradeToClose({});
    }

    const handleExecuteClose = () => {
        console.log('handleExecuteClose')
        const requestData = {
            'action': 'ExecuteCloseTrade',
            'data': selectedTradeToClose
        }
        genericRequest('Trade', requestData, setSelectedTradeToClose)
        setSelectedTradeToClose({});
    }

    const handleDeleteTrade = () => {
        console.log('handleDeleteTrade')
        const requestData = {
            'action': 'DeleteTrade',
            'data': selectedTradeToClose
        }
        genericRequest('Trade', requestData, setSelectedTradeToClose)
        setSelectedTradeToClose({});
    }

    return (
        <div>
            <Modal show="true" size="lg" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {"Trade ID : " + selectedTradeToClose.id + " - " + selectedTradeToClose.instrument.definiton}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Row className='align-middle'>
                        <Table size="sm" className='rounded-4 overflow-hidden mb-0'>
                            <thead className="table-primary">
                                <tr>
                                    <th className='align-middle'>Status</th>
                                    <th className='align-middle'>Expiry</th>
                                    <th className='align-middle'>Side</th>
                                    <th className='align-middle'>Type</th>
                                    <th className='align-middle'>Strike</th>
                                    <th className='align-middle' style={{width: "15%"}}>Open Price</th>
                                    <th className='align-middle' style={{width: "15%"}}>Open Fees</th>
                                    <th className='align-middle' style={{width: "15%"}}>Close Price</th>
                                    <th className='align-middle' style={{width: "15%"}}>Closing Fees</th>
                                    <th className='align-middle' style={{width: "25%"}}>Close Date&Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedTradeToClose.instrument.legs.map((leg, index) => (
                                    <tr key={index}>
                                        <td className='align-middle'>{leg.status}</td>
                                        <td className='align-middle'>{leg.expiry}</td>
                                        <td className='align-middle'>{leg.side}</td>
                                        <td className='align-middle'>{leg.type}</td>
                                        <td className='align-middle'>{leg.strike}</td>
                                        <td className='align-middle'>$ {leg.openPrice}</td>
                                        <td className='align-middle'>$ {leg.fees}</td>
                                        <td className='align-middle'>
                                            <Form.Group controlId={index}>
                                                <Form.Control size="sm" defaultValue={leg.currentPrice} onChange={onChangePrice}/>
                                            </Form.Group>
                                        </td>
                                        <td className='align-middle'>
                                            <Form.Group controlId={index}>
                                                <Form.Control size="sm" defaultValue={leg.fees} onChange={onChangeFees}/>
                                            </Form.Group>
                                        </td>
                                        <td className='align-middle'>
                                            <Form.Group controlId={index}>
                                                <Form.Control size="sm" defaultValue="" onChange={onChangeClosedateTime}/>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                    ))
                                }
                                <tr>
                                    <td colSpan={6} className='align-middle'>
                                        <InputGroup size="sm" className="my-1">
                                            <InputGroup.Text id="tradeDateTime">Open Trade Date&Time</InputGroup.Text>
                                            <Form.Control aria-label="tradeDateTime" defaultValue={selectedTradeToClose.tradeDateTime} disabled/>
                                        </InputGroup>
                                    </td>
                                    <td colSpan={4} className='align-middle'>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {selectedTradeToClose.status === 'Pending' ? (
                        <>
                            <Row>
                                <Col xs={8}></Col>
                                <Col xs={4} className='align-middle text-center mb-3'>
                                    <Button variant="primary" onClick={handleDeleteTrade}>
                                        Delete Trade
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : (selectedTradeToClose.status === 'SimulationOpen' ? (
                        <>
                            <Row>
                                <Col xs={8}></Col>
                                <Col xs={4} className='align-middle text-center mb-3'>
                                    <Button variant="secondary" onClick={handleBookClose}>
                                        Book Close Trade
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col xs={5}>
                                </Col>
                                <Col xs={3} className='align-middle text-center mb-3'>
                                    <Button variant="secondary" onClick={handleBookClose}>
                                        Book Close Trade
                                    </Button>
                                </Col>
                                <Col xs={4} className='align-middle text-center mb-3'>
                                    <Button variant="primary" onClick={handleExecuteClose}>
                                        Execute Closing Trade
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    ))}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalCloseTrade
