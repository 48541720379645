import httpInstance from "./httpClient"
import getCookie from "./getCookie"


const genericRequestWithSpinner = async (type, requestData, setResults, setSpinner) => {
    await httpInstance.post("/genericRequest", {
        'type': type,
        'data': requestData
    },{
        headers: {
            'X-CSRF-TOKEN': getCookie('csrf_access_token')
        }
    })
    .then(function (response) {
        if (response.data.success) {
            setResults(response.data.results);
        }
        else {
            console.log('genericRequestWithSpinner Error : [' + type + '] [' + response.data['error'] + ']')
        }
        setSpinner(false)
    })
    .catch(function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                console.log('User Logged Out')
            }
        }
        setSpinner(false);
        console.log('genericRequestWithSpinner error : ' + type + ' [' + error + ']');
    });
}

export default genericRequestWithSpinner
