import { useEffect } from 'react';

import httpInstance from '../utilities/httpClient';


function useRiskFreeRate(setRiskFreeRate) {
    const fetchRiskFreeRate = () => {
        httpInstance.get('/getRiskFreeRate')
        .then(function (response) {
            setRiskFreeRate(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                }
            }
            console.log(error);
        });
    }

    // call only once at startup
    useEffect(() => {
        fetchRiskFreeRate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchRiskFreeRate();
        }, 1000);

        return () => clearInterval(interval);
    })
}

export default useRiskFreeRate;
