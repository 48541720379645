import { useState } from "react"
import { Row, Col } from 'react-bootstrap'

import StaticDataMenu from "../components/StaticDataMenu";
import StaticDataDisplay from "../components/StaticDataDisplay";

import genericRequestWithSpinner from "../utilities/genericRequestWithSpinner"


const StaticData = () => {
    const [selectedStaticData, setSelectedStaticData] = useState("")
    const [entries, setEntries] = useState({'columns': [], 'lines': []})
    const [spinner, setSpinner] = useState(false)

    const handleSelect = (eventKey) => {
        setSelectedStaticData(eventKey)
        setEntries({'columns': [], 'lines': []})
        setSpinner(true)

        const requestData = {
            'dataType': eventKey,
            'action': 'Get',
            'data': null
        }
        genericRequestWithSpinner('StaticData', requestData, setEntries, setSpinner)
    }

    return (
        <>
            <Row className='row-nomargin-nopadding'>
                <Col lg={2} className='static-data-menu min-vh-100'>
                    <StaticDataMenu selectedStaticData={selectedStaticData}
                                    handleSelect={handleSelect}>
                    </StaticDataMenu>
                </Col>
                <Col className="pt-3 px-3">
                    {Object.keys(selectedStaticData).length !== 0 ? (
                        <StaticDataDisplay selectedStaticData={selectedStaticData} 
                                            entries={entries}
                                            setEntries={setEntries}
                                            spinner={spinner}
                                            setSpinner={setSpinner}>
                        </StaticDataDisplay>
                    ):('')}
                </Col>
            </Row>
        </>
    )
}

export default StaticData